.MuiInputLabel-root {
    color: rgb(45, 45, 45) !important;
}
/* .MuiSelect-select {
    color: rgb(0, 0, 0) !important;
}
.MuiInputBase-root {
    color: rgb(0, 0, 0) !important;
} */
.sottocampo .MuiOutlinedInput-notchedOutline {
    border-color: black;
}
em {
    font-size: small;
    color: '#C4CDD5';
}
.Mui-disabled {
    -webkit-text-fill-color: 'black' ;
}
.css-kwyju5.Mui-disabled {
    -webkit-text-fill-color: 'black' ;
}

.css-zrnq08.Mui-disabled {
    -webkit-text-fill-color: 'black' ;
}

tr:nth-child(even) {background-color: #f2f2f2;}

#stampa {width: 100%;border: 1px solid;}