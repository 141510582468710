.MuiTypography-root {
    text-align: justify !important;
    display: flex !important;
}

p {
    text-align: justify
}

#cookieBtn {
    background-color: white;
    font-size: large;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --fa-style-family-brands: $typography-global-font-family-07;
    --fa-font-brands: normal 400 1em/1 $typography-global-font-family-07;
    --fa-font-regular: normal 400 1em/1 $typography-global-font-family-06;
    --fa-style-family-classic: $typography-global-font-family-06;
    --fa-font-solid: normal 900 1em/1 $typography-global-font-family-06;
    pointer-events: auto;
    box-sizing: border-box;
    font-family: inherit;
    margin: 0;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    border: 1px solid transparent;
    border-radius: .25rem;
    display: inline-block;
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    user-select: none;
    vertical-align: middle;
    margin-bottom: .5rem !important;
    margin-top: 25px !important;
    border-width: 2px;
    font-weight: 700;
    padding: 5pt 30px;
    cursor: pointer;
    background-color: #fff;
    border-color: #fff;
    color: #00368f;
}

#myBtn {
    display: block;
    position: fixed;
    bottom: 20px;
    right: 30px;
    left: 30px;
    z-index: 99;
    font-size: 18px;
    border: none;
    outline: none;
    background-color: #1660a9;
    color: white;
    cursor: pointer;
    padding: 65px;
    border-radius: 4px;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
}

.center {
    margin: 0;
    position: absolute;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}